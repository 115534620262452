/**
 * 法语
 */
import frFR from "antd/locale/fr_FR";

const frLanguage =  {
    ...frFR,
    "service.language.chinese": "Chinois",
    "service.language.english": "Anglais",
    "service.language.french": "Français",
    "service.language.japanese": "Japonais",
    "service.language.korean": "Coréen",

    "service.my.order": "Ma commande",
    "service.my.account": "Mon compte",
    "service.my.cost": "Ma consommation",
    "service.logout": "Quitter",

    "service.menu.home": "Accueil",
    "service.menu.component": "Composants",
    "service.menu.workBench": "Table de travail",
    "service.menu.manualRecord": "Génération d'enregistrements",
    "service.menu.AIRecord": "Généré automatiquement",
    "service.menu.organManage": "Gestion organisationnelle",
    "service.menu.component.PPT": "PPT",
    "service.menu.Translation": "Génération de traduction",
    "service.menu.component.outline": "Résumé du ppt",
   

    "service.home.upload": "Télécharger",
    "service.home.add": "Ajouter",
    "service.home.avatar": "Image de tête",

    "service.nodata":"暂无数据",

    "service.auto.all": "Tous",
    "service.auto.generated": "Généré",
    "service.auto.editing": "Dans l'édition",

    "service.auto.list.status.all": "Tous",
    "service.auto.list.status.generated": "Généré",
    "service.auto.list.status.editing": "Dans l'édition",
    "service.auto.list.owner": "Propriétaire",
    "service.auto.list.updateTime": "Temps de mise à jour",

    "service.unknown":"Inconnu",


    "service.insert.ppt": "Importation de ppt",
    "service.insert.title": "Description",
    "service.insert.line1": "1, Concernant le temps d'importation: importez 10 pages, environ 30min. Une heure d'importation spécifique est indiquée après la sélection du fichier",
    "service.insert.line2": "2, la diapositive après l'importation ne prend pas en charge l'édition, seulement l'ajout de personnes numériques et la sélection de personnes numériques",
    "service.insert.line3": "3, après l'importation ppt, les diapositives et les notes sont extraites",
    "service.insert.line4": "4、Polices ppt recommandées avec Microsoft Yard ou Song",

    "service.record.openCamera": "Ouvrez la caméra",
    "service.record.closeCamera":"Fermer la caméra",
    "service.record.openMicro": "Ouvrez le microphone",
    "service.record.closeMicro":"Fermer le microphone",
    "service.record.enterFullScreen": "Plein écran",
    "service.record.exitFullscreen": "Quitter le plein écran",
    "service.record.startRecord": "Ouvrir l'enregistrement",
    "service.record.endRecord": "Fin de l'enregistrement",

    "service.record.list.searchTips":"Veuillez entrer le nom de l'œuvre",
    "service.record.list.owner":"Propriétaire",
    "service.record.list.updateTime":"Temps de mise à jour",

    "service.login.login": "Se connecter",
    "service.login.logout":"Quitter",

    "service.component.digitalVoice": "Voix humaine numérique",
    "service.component.digitalFigures": "Personnes numériques",
    "service.component.ppt": "Générer un ppt",
    "service.component.onGenerate": "Dans la génération...",

    "service.username": "Nom d'utilisateur",
    "service.email": "Boîte aux lettres",
    "service.phone": "Numéro de téléphone portable",
    "service.organization": "Organisation",


    "tips.login.notSupport":"Pas de soutien pour le moment",
    "tips.login.success": "Connexion réussie",
    "tips.login.fail": "La connexion a échoué",
    "tips.login.account": "Compte Veuillez entrer",
    "tips.login.password": "Veuillez entrer votre mot de passe",
    "tips.login.loginWithVerify": "使用验证码登录",
    "tips.login.check": "查看",
    "tips.privacy.title": "用户隐私协议"
}

export default frLanguage;