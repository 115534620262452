// axios的封装处理
import axios from "axios"
import publishNotice from "@/components/Notice";
import {getUserInfo, removeToken} from "@/utils/token";
// 1. 根域名配置
// 2. 超时时间
// 3. 请求拦截器 / 响应拦截器

const codeMessage = {
    202: '已接受请求，并进入后台排队（异步任务），但未处理完成',
    203: "请求成功，非授权信息，返回的meta信息不在原始的服务器，而是一个副本",
    204: "服务器成功处理，但未返回内容",
    205: "服务器成功处理，客户端（浏览器）应重置页面视图",
    206: "服务器成功处理了部分GET请求",
    400: '发出的请求有误（客户端请求语法错误），服务器没有进行新建或修改数据的操作',
    401: '用户没有权限（令牌、用户名、密码错误）或登录过期',
    403: '用户得到授权，但访问已被禁止',
    404: '请求的资源无法找到（资源不存在或URL错误），服务器没有进行操作',
    405: '客户端请求中的方法被服务器禁止或请求方法错误',
    406: '客户端请求格式错误或服务端返回内容客户端无法解析',
    410: '请求的资源被永久删除，且不会再得到',
    412: '客户端请求信息的先决条件错误',
    413: '由于请求的实体过大，服务器无法处理，因此拒绝请求',
    414: '请求的URL过长（URL通常为网址），服务器无法处理',
    415: '服务器无法处理请求附带的媒体格式',
    416: '客户端请求的范围无效',
    422: '当创建一个对象时，发生一个验证错误',
    500: '服务器发生错误，请检查服务器',
    501: "服务器不支持请求的功能，无法完成请求",
    502: '网关错误，从远程服务器接收到了一个无效的响应',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时，充当网关或代理的服务器，未及时从远端服务器获取请求',
    505: "服务器不支持请求的HTTP协议的版本，无法完成处理",
    604: "登录超时，请重新登录",
    603: "登录超时，请重新登录",//token失效
};

const request = axios.create({
    baseURL: window.envConfig['API_BASE_PORT'],
    timeout: 35000,
    headers: {
        'Content-Type': "application/json; charset=utf-8",
    },

})

// 添加请求拦截器
// 在请求发送之前 做拦截 插入一些自定义的配置 [参数的处理]
request.interceptors.request.use((config) => {
    // 操作这个config 注入token数据
    // 1. 获取到token
    // 2. 按照后端的格式要求做token拼接
    const userInfo = getUserInfo();
    if (userInfo && userInfo.token){
        config.headers["token"] = userInfo.token
    }
    return config
}, (error) => {
    return Promise.reject(error)
})


request.interceptors.response.use((response) => {
    if (response && response.data) {
        const {code, msg, message } = response.data
        const { url } = response.config;
        let errorText = null
        if(url && url.includes("sys-user/passport/getVerifyCode")) {
            errorText = message || '未知错误';
        } else {
            errorText = msg || message || codeMessage[code] || '未知错误';
        }
        if (code && code !== 1 && code !== 200) {
           
            const { url } = response.config;
            if (code === 604 || code === 603) {
                publishNotice("warning", "登录超时，请重新登录")
                removeToken()
                setTimeout(() => {
                    window.location.href = '/login'
                }, 1000)
            }
            return Promise.reject({code, url, errorText})
        }
    }
    return response.data
}, (error) => {
    console.log(error, 1111)
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // 监控401 token失效
    // console.dir(error)

    if (error.message.includes("timeout")) {
        return Promise.reject("请求超时，请检查网络或稍后再试");
    }
    const response = error.response

    if (response && response.status) {
        console.log(response, "resp")
    }
    return Promise.reject(error);
})

export {request}



