import React from 'react';
import { Space, Spin } from 'antd';
import "./index.scss";
/**
 * 加载中组件
 * @see @/router/index.js
 * @returns {Element}
 * @constructor
 */
const Loading = () => {
    return (
        <>
            <div className="loadingComp">
                <Space>
                    <Spin size="large" />
                </Space>
            </div>
        </>
    )
}

export default Loading;