// 封装基于ls存取删三个方法

const TOKENKEY = 'token_key'

function setToken(token) {
    return localStorage.setItem(TOKENKEY, token)
}

function getToken() {
    return localStorage.getItem(TOKENKEY);
}

function removeToken() {
    return localStorage.removeItem(TOKENKEY);
}

/**
 * 获取userInfo
 * @returns {any|null}
 */
function getUserInfo() {
    return localStorage.getItem(window.envConfig.ROOT_UER_INFO) && localStorage.getItem(window.envConfig.ROOT_UER_INFO) !== "undefined" ? JSON.parse(localStorage.getItem(window.envConfig.ROOT_UER_INFO)) : null;
}

function setUserInfo(userInfo) {
    if (userInfo) {
        localStorage.setItem(window.envConfig.ROOT_UER_INFO, JSON.stringify(userInfo));
    }
}

function removeInfo() {
    localStorage.removeItem(window.envConfig.ROOT_UER_INFO);
}

export {
    getUserInfo,
    removeInfo,
    setUserInfo,
    setToken,
    getToken,
    removeToken
}