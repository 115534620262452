export const ComponentSvg = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.0725 7.21673C21.0725 6.94522 20.9367 6.74159 20.6652 6.60584L13.1989 3.21204C12.9952 3.14416 12.7916 3.14416 12.6558 3.21204L5.18948 6.60584C4.91798 6.74159 4.78223 6.94522 4.78223 7.21673C4.78223 7.48823 4.91798 7.69186 5.18948 7.82761L12.6558 11.2214C12.7237 11.2893 12.8595 11.2893 12.9274 11.2893C12.9952 11.2893 13.131 11.2893 13.1989 11.2214L20.6652 7.82761C20.9367 7.69186 21.0725 7.48823 21.0725 7.21673Z"/>
            <path
                d="M11.8414 12.7149L4.37507 9.32107C4.17144 9.18531 3.89994 9.25319 3.76418 9.38894C3.56056 9.45682 3.4248 9.72832 3.4248 9.93195V18.0771C3.4248 18.3486 3.56056 18.5522 3.83206 18.688L11.2984 22.0818C11.3663 22.1496 11.5021 22.1496 11.5699 22.1496C11.7057 22.1496 11.8414 22.0818 11.9093 22.0139C12.1129 21.8781 12.2487 21.6745 12.2487 21.4709V13.3258C12.2487 13.0542 12.1129 12.8506 11.8414 12.7149Z"/>
            <path
                d="M22.091 9.3898C21.8873 9.25405 21.6837 9.25405 21.4122 9.32193L13.9458 12.7157C13.7422 12.8515 13.6064 13.0551 13.6064 13.3266V21.4717C13.6064 21.6754 13.7422 21.9469 13.9458 22.0147C14.0816 22.0826 14.2173 22.1505 14.2852 22.1505C14.3531 22.1505 14.4888 22.1505 14.5567 22.0826L22.0231 18.6888C22.2946 18.5531 22.4303 18.3494 22.4303 18.0779V9.93281C22.4303 9.72918 22.2946 9.45768 22.091 9.3898Z"/>
        </svg>
    )
}