import ja_JP from "antd/locale/ja_JP";

/**
 * 日语
 */
const jpLanguage = {
  ...ja_JP,
  "service.language.chinese": "中国語",
  "service.language.english": "英語",
  "service.language.french": "フランス語",
  "service.language.japanese": "日本語",
  "service.language.korean": "韓国語",
  "service.language.vietnamese": "ベトナム語",
  "service.language.russian": "ロシア語",
  "service.language.german": "ドイツ語",
  "service.language.italian": "イタリア語",
  "service.language.portuguese": "ポルトガル語",
  "service.language.thai": "タイ語",
  "service.language.spanish": "スペイン語",
  "service.language.indonesian": "インドネシア語",

  "service.my.order": "私の注文",
  "service.my.account": "私のアカウント",
  "service.my.cost": "私の消耗",
  "service.logout": "ログオンの終了",
  "service.my.recharge": "れんが充填",

  "service.menu.home": "トップページ",
  "service.menu.component": "コンポーネント",
  "service.menu.workBench": "ワークベンチ",
  "service.menu.manualRecord": "記録生成",
  "service.menu.AIRecord": "自動生成",
  "service.menu.organManage": "組織管理",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "翻訳生成",
  "service.menu.component.outline": "PPTアウトライン",

  "service.menu.system": "ユーザー管理",
  "service.menu.system.user": "組織ユーザー",
  "service.menu.system.role": "ロール管理",
  "service.menu.system.depart": "部署管理",


  "service.translation.uploadSuccess": "アップロードに成功",
  "service.translation.generating": "翻訳生成中",
  "service.translation.wait": "お待ちください...",
  


  "service.home.upload": "アップロード",
  "service.home.add": "追加",
  "service.home.avatar": "アイコン",
  "service.home.uploadPhoto": "画像をアップロード",
  "service.home.createAvatar": "アバターの作成",


  "service.auto.all": "すべて",
  "service.auto.generated": "生成された",
  "service.auto.editing": "編集中",


  "service.nodata": "データなし",
  "service.unknown": "不明",
  "service.unName": "名前なし",

  "service.auto.list.status.all": "すべて",
  "service.auto.list.status.generated": "生成されました",
  "service.auto.list.status.editing": "編集中",
  "service.auto.list.owner": "所有者",
  "service.auto.list.updateTime": "更新日時",


  "service.insert.ppt": "PPT導入",
  "service.insert.title": "説明",
  "service.insert.line1": "1.導入時間について：10ページを導入して、大体30 minを要します。ファイルを選択すると、具体的なインポート時間がプロンプトされます",
  "service.insert.line2": "2.導入後のスライドは編集をサポートせず、デジタル人の追加とデジタル人の声の選択のみをサポートする",
  "service.insert.line3": "3.PPTを導入すると、スライドとマークアップが抽出される",
  "service.insert.line4": "4.PPTフォントはMicrosoft雅黒または宋体を使用することをお勧めします",


  "service.record.openCamera": "カメラを開く",
  "service.record.closeCamera": "カメラを閉じる",
  "service.record.openMicro": "マイクを",
  "service.record.closeMicro": "マイクを切る",
  "service.record.enterFullScreen": "全画面表示",
  "service.record.exitFullscreen": "全画面表示を終了",
  "service.record.startRecord": "録画を有効にする",
  "service.record.endRecord": "録画終了",
  "service.record.rejectedVideo": "あなたは録画許可を拒否しました、私たちはビデオを録画しません",
  "service.record.rejectedMicro": "マイクの権限をおしらいました。設備を確認してください",
  "service.record.noMicro": "マイク設備が見つからなかった",
  "service.record.microDisabled": "あなたのマイクは使えません, 設備をチェックしてください",
  "service.record.wait20": "二十秒待ってから終わります",
  "service.record.checkMicro": "設備検査に失敗しました, マイクを確認してください",
  "service.record.checkCarma": "設備検査に失敗しました, カメラをチェックしてください",
  "service.record.rejectedCamera": "あなたはカメラ権限を拒否しました, 設備をチェックしてください",
  "service.record.noCamera": "カメラ設備が見つからなかった",
  "service.record.cameraDisabled": "あなたのカメラは使用できませんので、設備を確認してください。",

  "service.record.list.searchTips": "作品名を入力してください",
  "service.record.list.owner": "所有者",
  "service.record.list.updateTime": "更新時間",
  "service.record.list.uploadTime": "アップロード時間",
  "service.record.list.opr": "操作",
  "service.record.list.title": "タイトル",
  "service.record.list.gender": "性別",


  "service.avatar.createAvatar": "デジタルマンを作成/編集",
  "service.avatar.gender": "デジタルマン性別",
  "service.avatar.female": "女性",
  "service.avatar.male": "男性",
  "service.avatar.uploadType": "フォーマットは .jpg .png",
  "service.avatar.upload.rule-1": "顔の完全な画像をアップロードして、写真は標準的であればあるほど効果的です",
  "service.avatar.upload.rule-2": "本人の顔をアップロードしなければならなくて、目鼻立ちのはっきりした写真、肩の区域をカバーして、提案の大きさは10MBを超えません",
  "service.avatar.upload.rule-3": "背景は緑のカーテンがおすすめです",


  "service.PPT.create": "PPTの作成/編集",
  "service.PPT.description": "説明",
  "service.PPT.inputDescription": "説明を入力してください",
  "service.PPT.rebuilding": "再生成中...",
  "service.PPT.generateAnOutline": "アウトライン内の生成...",
  "service.PPT.rebuild": "再生成",
  "service.PPT.next": "次へ",
  "service.PPT.back": "戻る",
  "service.PPT.outlineDescription": "アウトラインの編集をダブルクリックするか、そのまま続行できます",
  "service.PPT.chapter": "章",
  "service.PPT.language": "言語",
  "service.PPT.pleaseSelectALanguage": "言語を選択してください",
  "service.PPT.size50": "PPTの大きさは50Mを超えてはいけません",
  "service.PPT.add": "追加",
  "service.PPT.del": "削除",
  "service.PPT.demote": "降格",
  "service.PPT.selTheme": "テーマを選ぶ",


  "service.record.list.digitalVoice": "デジタル音声の作成/編集",
  "service.record.list.name": "名称",
  "service.record.list.introduction": "こくじ",
  "service.record.list.voiceGender": "おんせいせい",
  "service.record.list.upload": "アップロード",
  "service.record.list.clickUpload": "アップロードをクリック",
  "service.record.list.agree": "読んで同意しました",
  "service.record.list.agreement": "デジタル音声作成と使用プロトコル",
  "service.record.list.uploadType": "フォーマットは .mp3 .wav",
  "service.record.list.inputName": "名前を入力してください",
  "service.record.list.inputIntroduction": "概要を入力してください",
  "service.record.list.selectGender": "性別を選択してください",
  "service.record.list.uploadFile": "ファイルをアップロードしてください",
  "service.record.list.pleaseAgree": "協議に同意してください",
  "service.record.list.generate": "つくる",
  "service.record.list.generateFailed": "生成に失敗",
  "service.record.list.gender.female": "女",
  "service.record.list.gender.male": "男",
  "service.record.list.selectVocal": "デジタルサウンドの選択",
  "service.record.list.selectModel": "PPT、デジタル音声、デジタル人間を選択してください",
  "service.record.list.onPublish": "公開中...",
  "service.record.list.publish": "リリース",
  "service.record.list.requireVideoName": "ビデオ名は必須フィールドです",
  "service.record.list.videoName": "動画リスト",
  "service.record.list.insert": "挿入",
  "service.record.list.ppt.uploadFailed": "PPTアップロード失敗",
  "service.record.list.parsing": "解析中...",
  "service.record.list.ppt.parseSuccess": "PPT解析に成功",
  "service.record.list.ppt.parseFailed": "PPT解析失敗",
  "service.record.list.selectAva": "アバターを選択",
  "service.record.cameraTip": "カメラまたはマイクをオンにしていません, 続行しますか？",
  "service.record.videoName": "ビデオ名",


  "service.cost.amount": "消費金額",
  "service.cost.type": "消費タイプ",
  "service.cost.pieces": "消費件数",
  "service.cost.time": "消費時間",
  "service.cost.videoGenerate": "ビデオ生成",
  "service.cost.DH": "デジタルマン製作",
  "service.cost.DV": "デジタル音声製作",
  "service.cost.searchTips": "消費源を選択してください",
  "service.cost.video": "ビデオ",
  "service.cost.vocal": "サウンド",


  "service.order.selRechargeSource": "チャージ元を選択してください",
  "service.order.selRechargeType": "チャージタイプを選択してください",
  "service.order.selPaymentState": "支払ステータスを選択してください",
  "service.order.inputID": "注文番号を入力してください",
  "service.order.recharge.wechat": "ウィーチャット",
  "service.order.recharge.Alipay": "アリペイ",
  "service.order.recharge.CloudQuickPass": "雲閃付",
  "service.order.payment.success": "支払い済み",
  "service.order.payment.failed": "未払い",
  "service.order.invoice": "請求書を発行する",
  "service.order.id": "オーダー番号",
  "service.order.rechargeSource": "チャージ元",
  "service.order.rechargeType": "チャージタイプ",
  "service.order.paymentState": "支払ステータス",
  "service.order.num": "オーダー数",
  "service.order.orderTime": "注文時間",
  "service.order.payTime": "支払時間",
  "service.order.video": "ビデオ",
  "service.order.digitalVoice": "デジタル音声",


  "service.translation.selectLan": "言語を選択してください",
  "service.translation.selectVoice": "サウンドを選択してください",
  "service.translation.video": "ビデオ翻訳",
  "service.translation.originalLan": "オリジナル言語",
  "service.translation.selOriginalLan": "元の言語を選択してください",
  "service.translation.translLan": "翻訳言語",
  "service.translation.selTranslLan": "翻訳言語を選択してください",
  "service.translation.uploadVideo": "動画をアップロード",
  "service.translation.uploadVideoVail": "動画をアップロードしてください",


  "service.login.login": "ログイン",
  "service.login.logout": "終了",
  "service.login.register": "登録",
  "service.login.registerTips": "アカウントの登録",
  "service.login.seeYouAgain": "私たちは再びあなたに会えてとても嬉しいです！",
  "service.login.welcome": "おかえりなさい!",
  "service.login.codeError": "確認コードが誤っているか、期限切れです",
  "service.login.phoneOrEmail": "携帯電話 / メールアドレス",
  "service.login.code": "確認コード",
  "service.login.send": "送る",
  "service.login.accountPassword": "アカウントとパスワードを使用してログインしてください",
  "service.login.privacy": "ユーザープライバシー契約に同意する",


  "service.component.digitalVoice": "デジタル音声",
  "service.component.digitalFigures": "ディジタル人",
  "service.component.ppt": "PPTの生成",
  "service.component.onGenerate": "生成中",
  "service.component.timeEstimate": "時間予算",
  "service.component.timeEstimateDetail": "xxx分かかり、xxx元かかる見込みです。",
  "service.component.paySuccess": "支払い成功",


  "service.common.publishSuccess": "公開に成功",
  "service.common.deleteSuccess": "削除に成功",
  "service.common.deleteFail": "削除に失敗",
  "service.common.saveSuccess": "保存に成功しました",
  "service.common.saveFail": "保存に失敗しました",
  "service.common.success": "成功",
  "service.common.Edit": "編集",
  "service.common.confirm": "確認",
  "service.common.cancel": "キャンセル",
  "service.common.setting": "設定",
  "service.common.None": "一時空",
  "service.common.tip": "ヒント",
  "service.common.title": "タイトル",
  "service.common.enterTitle": "タイトルを入力してください",
  "service.common.theBuildSucceeded": "ビルドに成功しました",
  "service.common.downloadFailed": "ダウンロード失敗",
  "service.common.logoutSuccess": "ログアウトに成功しました",
  "service.common.logoutFail": "ログアウトに失敗しました",
  "service.common.confirmLogout": "ログアウトの確認",
  "service.common.confirmLogoutTips": "本当にログアウトしますか？",
  "service.common.uploadFailed": "アップロードに失敗しました",
  "service.common.uploadFileTooLarge": "ファイルサイズは5 M以内",
  "service.common.uploadFileFormatError": "JPG/PNG形式のファイルしかアップロードできません",
  "service.common.emailExist": "メールアドレスは既に存在します",


  "service.notice.errorRequest": "要求エラー: ",
  "service.notice.timeout": "要求がタイムアウトしました。ネットワークを確認するか、後で再試行してください",
  "service.notice.request202": "要求を受け入れ、バックグラウンドキューに入った（非同期タスク）が、処理完了していない",
  "service.notice.request203": "要求は成功し、許可されていない情報、返されたmeta情報は元のサーバではなく、コピーです",
  "service.notice.request204": "サーバは正常に処理されましたが、コンテンツは返されませんでした",
  "service.notice.request205": "サーバーは正常に処理され、クライアント（ブラウザ）はページビューをリセットする必要があります",
  "service.notice.request206": "サーバはGET要求の一部を正常に処理しました",
  "service.notice.request400": "発行された要求に誤りがあり（クライアント要求構文エラー）、サーバはデータの新規作成または変更を実行していません",
  "service.notice.request401": "ユーザーに権限がない（トークン、ユーザー名、パスワードが間違っている）か、ログインが期限切れになっている",
  "service.notice.request403": "ユーザーは許可されているが、アクセスは禁止されている",
  "service.notice.request404": "要求されたリソースが見つかりません（リソースが存在しないかURLエラー）、サーバが操作していません",
  "service.notice.request405": "クライアント要求内のメソッドがサーバによって禁止されているか、リクエストメソッドが間違っている",
  "service.notice.request406": "クライアント要求フォーマットが間違っているか、サービス側がコンテンツクライアントに戻って解析できない",
  "service.notice.request410": "要求されたリソースは永久に削除され、二度と取得されません",
  "service.notice.request412": "クライアント要求情報の前提条件エラー",
  "service.notice.request413": "要求エンティティが大きすぎてサーバーが処理できないため、要求を拒否します",
  "service.notice.request414": "要求されたURLが長すぎて（URLは通常Webアドレス）、サーバは処理できません",
  "service.notice.request415": "サーバは要求に付属するメディアフォーマットを処理できませんでした",
  "service.notice.request416": "クライアント要求の範囲が無効です",
  "service.notice.request422": "オブジェクトを作成したときに検証エラーが発生しました",
  "service.notice.request500": "サーバーでエラーが発生しました。サーバーを確認してください",
  "service.notice.request501": "サーバは要求の機能をサポートしておらず、要求を完了できません",
  "service.notice.request502": "ゲートウェイエラー。リモートサーバから無効な応答を受信しました",
  "service.notice.request503": "サービスは使用できません。サーバは一時的に過負荷またはメンテナンスされます。",
  "service.notice.request504": "ゲートウェイがタイムアウトしました。ゲートウェイまたはエージェントのサーバとして機能し、リモートサーバからの要求をタイムリーに取得できませんでした",
  "service.notice.request505": "サーバは要求されたHTTPプロトコルのバージョンをサポートしておらず、処理を完了できません",
  "service.notice.request604": "ログインタイムアウト、再ログインしてください",
  "service.notice.request603": "ログインタイムアウト、再ログインしてください",

  "service.username": "ユーザー名",

  "service.email": "メールボックス",
  "service.phone": "携帯番号",
  "service.organization": "組織",
  "service.OK": "確認",

  "tips.input.account": "あなたのアカウントを入力してください",
  "tips.input.password": "パスワードを入力してください",
  "tips.login.notSupport": "サポートを保留中",
  "tips.login.success": "ログイン成功",
  "tips.login.fail": "ログインに失敗しました",
  "tips.login.acc": "アカウント",
  "tips.login.pass": "パスワード",
  "tips.login.agreeTips": "ユーザープライバシー契約に同意するかどうか",
  "tips.login.account": "アカウントを入力してください",
  "tips.login.password": "パスワードを入力してください",
  "tips.login.loginWithVerify": "確認コードを使用してログインして登録します",
  "tips.login.check": "表示＃ヒョウジ＃",
  "tips.privacy.title": "ユーザープライバシープロトコル",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "アカウントまたはパスワードが間違っている",
  "tips.login.getAVerificationCode": "認証コードの取得",
  "tips.login.RetryinSeconds": "秒後に再試行",

  "btn.text.release": "リリース",
  "btn.text.modify": "修正",

  "service.language.cn": "中国語（デフォルト）",
  "service.language.en": "英語",
  "service.language.ja": "日本語",
  "service.language.ru": "ロシア語",
  "service.language.ko": "韓国語",
  "service.language.de": "ドイツ語",
  "service.language.fr": "フランス語",
  "service.language.pt": "ポルトガル語",
  "service.language.es": "スペイン語",
  "service.language.it": "イタリア語",
  "service.language.th": "タイ語",

  "service.user.center": "ユーザーセンター",
  "service.user.updateAvatar": "プロフィール写真の変更",
  "service.user.avatarFormat": "画像は500*500ピクセル以上で、JPG、JPEG、PNG、SVG形式をサポートし、サイズは5Mを超えない",
  "service.user.nickname": "ニックネーム",
  "service.user.cancelAccount": "アカウントの解約",
  "service.user.cancelAccountWarning": "ログアウト後は回復できませんので、慎重に操作してください",
  "service.user.logOff": "ログアウト",
  "service.user.verification": "身元認証",
  "service.user.verifyIdentity": "あなたのアカウントの安全のために、次の操作を行う前に先に身元認証を行ってください。",
  "service.user.useMobile": "使用",
  "service.user.completeVerification": "認証を完了します",
  "service.user.send": "送信する",
  "service.user.enterCode": "認証コードを入力してください",
  "service.user.resend": " 再送する",
  "service.user.changePassword": "パスワードを変更する",
  "service.user.enterNewPassword": "新しいパスワードを入力してください",
  "service.user.LimitPassword": "新しいパスワードを入力してください。パスワードの長さは 6 - 20 桁で、大文字、小文字、数字、特殊文字のうち 2 種類を含む必要があります",
  "service.user.LimitLengthPassword": "パスワードの長さは 6 文字から 20 文字の間でなければなりません",
  "service.user.LimitFormatPassword": "パスワードは、大文字、小文字、数字、特殊文字のうち 2 種類を含まなければなりません",
}

export default jpLanguage;
