/**
 * 用户相关接口
 */
import {request} from "@/utils";
import {allApis} from "@/apis/api";

export function register(registerParams) {
    return request({
        url: '/authorizations',
        method: 'POST',
        data: registerParams
    })
}


export function getUserDetail(){
    return request.get(allApis.user.detail);
}
/**
 * 发送验证码
 * @param mobile 手机号
 */
export function sendVerifyCode({mobile}) {
    return request.get(allApis.user.getVerifyCode,{ params: {mobile} })
}
/**
 * 发送邮箱验证码
 * @param email
 */
export function sendEmailCode(email) {
    return request.get(`${allApis.user.sendCode}/${email}`)
}

/**
 * 邮箱身份验证
 * @param email
 * @param code
 */
export function confirmEmailVerifyCode({email, code}) {
    return request.get(`${allApis.user.checkCode}/${email}/${code}`)
  }
/**
 * 登录
 * @param phone
 * @param email
 * @param password
 * @param loginBy
 * @param type 密码1 验证码2
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function userLogin({username,  password, type}) {
    return request.post(allApis.user.login, {
        username, password, type
    })
}

/**
 * 登出操作
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function userLogout(){
    return request.get(allApis.user.logout);
}

export function updateUserInfo(data) {
    return request.post(allApis.user.updateUserInfo, data)
}
export function updateMobile({mobile, code}) {
    return request.get(`${allApis.user.updateMobile}/${mobile}/${code}`)
}
export function setEmail({email, code}) {
    return request.get(`${allApis.user.setEmail}/${email}/${code}`)
}
export function setPassword({password}) {
    return request.get(allApis.user.setPassword, { params: {password}})
}
export function cancelAaccount() {
    return request.get(allApis.user.cancelAaccount)
}

export function getVerifyCode({ mobile }) {
    return request.get(allApis.user.getVerifyCode, { params: {mobile}})
}

export function confirmVerifyCode({mobile, code}) {
    return request.get(allApis.user.confirmVerifyCode, { params: {mobile, code}})
}