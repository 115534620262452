// 组合redux子模块 + 导出store实例
import {configureStore} from '@reduxjs/toolkit';
import userReducer from './modules/user';
import settingReducer from "@/store/modules/setting";

/**
 * redux的配置
 */
export default configureStore({
    reducer: {
        user: userReducer,
        setting: settingReducer,
    }
});