export const allApis = {
  user: {
    login: "/ucenter-login/passport/login",
    logout: "/ucenter-login/logout",
    register: "/sys-user/register",
    detail: "/sys-user/detail",
    getCode: "sys-user/passport/getVerifyCode",
    updateUserInfo: "/ucenter-user-info/updateUserInfo",
    updateMobile: "/ucenter-user-info/updateMobile",
    sendCode: "/sms/passport/getCode", //获取邮箱验证码
    checkCode: "/sms/checkCode", //验证身份
    setPassword: "/ucenter-login/setPassword",
    setEmail: "/ucenter-user-info/setEmail",
    cancelAaccount: "/ucenter-user-info/cancelAaccount",
    getVerifyCode: "/sms/passport/sendCode",
    confirmVerifyCode: "/sms/passport/verifyCode",

  },
  file: {
    simpleUpload: "/aora-file/simpleUpload",
    uploadLocal: "/aora-file/uploadLocal",
    partUpload: "/aora-file/partUpload",
    combineFile: "/aora-file/combineFile",
    analysisPPT: "/aora-file/analysis",
    analysisPPTNew: "/ucenter-annex-info/analysis",
    uploadFiles: "/ucenter-annex-info/upload",
    getFilesById: "ucenter-annex-info/getFileInfoById",
  },
  org: {
    getOrgById: "/aora-organize/getOrgInfoByI",
  },
  video: {
    getVideoList: "/ucenter-auto-generate/getAutoListPage",
    remove: "/ucenter-auto-generate",
    saveAutoGenerate: "/ucenter-auto-generate/saveAutoGenerate",
  },
  digital: {
    ppt: {
      list: `/ucenter-component-ppt/getPptLstPage`,
      add: `/ucenter-component-ppt/generatePPT`,
      remove: `/ucenter-component-ppt`,
      update: `/ucenter-component-ppt/update`,
      getGenerateProgress: `/ucenter-component-ppt/getGenerateProgress`,
      themeList: `/ucenter-component-ppt/getThemeList`,
      generateAnOutline: `/ucenter-component-ppt/getPPTOutline`,
    },
  },
  pay: {
    getTopupList: "/dict-topup/getTopupList",
    createOrder: "/ucenter-order/createOrder",
    returnOrderMessage:"/ucenter-order/passport/callback",
    getAccountBalance: "/ucenter-account-balance/getAccountBalance",
    getOrderStatus:"/ucenter-order/getOrderStatus",
    buyQuantity : "/ucenter-account-balance/buyQuantity",
    getOrderList: "/ucenter-order/getOrderList"
  },
};
