import {createSlice} from "@reduxjs/toolkit";
import {languageAreaMap} from "@/utils/language";
import { LANGUAGE } from "@/constants";
const settingStore = createSlice({
    name: "setting",
    // 数据状态
    initialState: {
        locale: languageAreaMap.get(localStorage.getItem(LANGUAGE) || "zh-CN") ?? "en",
        headerHeight: 72,
    },
    // 同步修改方法
    reducers: {
        changeLanguage(state, action) {
            state.locale = action.payload;
            localStorage.setItem(LANGUAGE, action.payload);
        }
    }
});

// 解构出actions
const {changeLanguage} = settingStore.actions;


const settingReducer = settingStore.reducer;

export {
    changeLanguage,
}
export default settingReducer;
