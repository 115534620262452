/**
 * 英语
 */
import enUS from "antd/locale/en_US";

const engLanguage = {
  ...enUS,

  "service.language.chinese": "Chinese",
  "service.language.english": "English",
  "service.language.french": "French",
  "service.language.japanese": "Japanese",
  "service.language.korean": "Korean",
  "service.language.vietnamese": "Vietnamese",
  "service.language.russian": "Russian",
  "service.language.german": "German",
  "service.language.italian": "Italian",
  "service.language.portuguese": "Portuguese",
  "service.language.thai": "Thai",
  "service.language.spanish": "Spanish",
  "service.language.indonesian": "Indonesian",


  "service.my.order": "My order",
  "service.my.account": "My account",
  "service.my.cost": "Money cost",
  "service.logout": "Logout",
  "service.my.recharge": "Diamond recharge",

  "service.menu.home": "HomePage",
  "service.menu.component": "Component",
  "service.menu.workBench": "WorkBench",
  "service.menu.manualRecord": "Record Generation",
  "service.menu.AIRecord": "Auto Generation",
  "service.menu.organManage": "Organizational Management",
  "service.menu.component.PPT": "PPT",
  "service.menu.Translation": "Translation Generation",
  "service.menu.component.outline": "PPT outline",
  "service.menu.system": "User management",
  "service.menu.system.user": "Organizational users",
  "service.menu.system.role": "Role management",
  "service.menu.system.depart": "Department management",

  "service.home.upload": "Upload your Content",
  "service.home.add": "Add",
  "service.home.avatar": "Picture",
  "service.home.uploadPhoto": "Upload Photo",
  "service.home.createAvatar": "Create Picture",

  "service.auto.all": "All",
  "service.auto.generated": "Generated",
  "service.auto.editing": "Editing",

  "service.nodata": "No Data",
  "service.unknown": "Unknown",
  "service.unName": "UnNamed",


  "service.auto.list.owner": "Owner",
  "service.auto.list.updateTime": "Update Time",

  "service.auto.list.status.all": "All",
  "service.auto.list.status.generated": "Generated",
  "service.auto.list.status.editing": "Editing",

  "service.insert.ppt": "Slides Import",
  "service.insert.title": "Instruction",
  "service.insert.line1": "1. Import Time: Importing 10 pages needs about 30 minutes. When you select a file, you will be prompted with a specific import time",
  "service.insert.line2": "2. After importing, the slide does not support editing, and only supports adding digital people and selecting digital human voices",
  "service.insert.line3": "3. After importing PPT, the slides and annotations will both be extracted",
  "service.insert.line4": "4. It is recommended to use Microsoft Yahei or Song typeface for PPT fonts",


  "service.record.openCamera": "Open Camera",
  "service.record.closeCamera": "Exit Camera",
  "service.record.openMicro": "Open MicroPhone",
  "service.record.closeMicro": "Exit MicroPhone",
  "service.record.enterFullScreen": "Enter FullScreen",
  "service.record.exitFullscreen": "Exit FullScreen",
  "service.record.startRecord": "Start Record",
  "service.record.endRecord": "End Record",
  "service.record.rejectedVideo": "You refused the recording authorization, and we will not record the video.",
  "service.record.rejectedMicro": "You have rejected the microphone permission. Please check the device.",
  "service.record.noMicro": "No microphone device found",
  "service.record.microDisabled": "Your microphone is not available. Please check the device.",
  "service.record.wait20": "Please wait for 20 seconds before it ends.",
  "service.record.checkMicro": "The device detection failed. Please check the microphone.",
  "service.record.checkCarma": "Device detection failed, please check the camera.",
  "service.record.rejectedCamera": "You have denied the camera permission. Please check the device.",
  "service.record.noCamera": "The camera device was not found.",
  "service.record.cameraDisabled": "Your camera is not available. Please check the device.",

  "service.record.list.all": "All",
  "service.record.list.generated": "Generated",


  "service.record.list.searchTips": "Please enter the name of the work",
  "service.record.list.owner": "Owner",
  "service.record.list.updateTime": "UpdateTime",
  "service.record.list.uploadTime": "UploadTime",
  "service.record.list.opr": "Operation",
  "service.record.list.title": "Title",
  "service.record.list.gender": "Gender",


  "service.avatar.createAvatar": "Create/Edit Avatar",
  "service.avatar.gender": "Digital person gender",
  "service.avatar.female": "Female",
  "service.avatar.male": "Male",
  "service.avatar.uploadType": "The format is .jpg .png",
  "service.avatar.upload.rule-1": "Upload a full picture of your face. The more standard the photo, the better the result",
  "service.avatar.upload.rule-2": "You need to upload a clear photo of your face, features, covering the shoulder area, and the recommended size is not more than 10MB",
  "service.avatar.upload.rule-3": "Green screen is recommended for picture background",


  "service.PPT.create": "Create/Edit PPT",
  "service.PPT.description": "Description",
  "service.PPT.inputDescription": "Please enter description",
  "service.PPT.rebuilding": "Regenerating now...",
  "service.PPT.rebuild": "regeneration",
  "service.PPT.generateAnOutline": "generateAnOutline...",
  "service.PPT.next": "next",
  "service.PPT.back": "back",
  "service.PPT.outlineDescription": "You can double-click to edit the outline, or keep it as is and continue.",
  "service.PPT.chapter": "chapter",
  "service.PPT.language": "language",
  "service.PPT.pleaseSelectALanguage": "please Select A Language",
  "service.PPT.size50": "The size of PPT cannot exceed 50M.",
  "service.PPT.add": "Add",
  "service.PPT.del": "Delete",
  "service.PPT.demote": "Demote",
  "service.PPT.selTheme": "Select Theme",


  "service.record.list.digitalVoice": "Create/Edit Digital Vocal",
  "service.record.list.name": "Name",
  "service.record.list.introduction": "Introduction",
  "service.record.list.voiceGender": "Vocal gender",
  "service.record.list.upload": "Upload",
  "service.record.list.clickUpload": "Click to upload",
  "service.record.list.agree": "I have read and agree",
  "service.record.list.agreement": "Digital Voice Creation and Use Agreement",
  "service.record.list.uploadType": "The format is .mp3 .wav",
  "service.record.list.inputName": "Please enter name",
  "service.record.list.inputIntroduction": "Please enter introduction",
  "service.record.list.selectGender": "Please select gender",
  "service.record.list.uploadFile": "Please upload file",
  "service.record.list.pleaseAgree": "Please agree to the agreement",
  "service.record.list.generate": "Generate",
  "service.record.list.generateFailed": "Generation failed",
  "service.record.list.gender.female": "Female",
  "service.record.list.gender.male": "Male",
  "service.record.list.selectVocal": "Select Digital Vocal",
  "service.record.list.selectModel": "Please select PPT, Digital Voice and Digital person",
  "service.record.list.onPublish": "In release...",
  "service.record.list.publish": "Publish",
  "service.record.list.requireVideoName": "The video name is a required field",
  "service.record.list.videoName": "Video Name",
  "service.record.list.insert": "Insert",
  "service.record.list.ppt.uploadFailed": "PPT upload failed",
  "service.record.list.parsing": "In parsing...",
  "service.record.list.ppt.parseSuccess": "PPT parsed successfully",
  "service.record.list.ppt.parseFailed": "PPT parsing failed",
  "service.record.list.selectAva": "Select picture",
  "service.record.cameraTip": "You have not turned on the camera or microphone. Do you want to continue?",
  "service.record.videoName": "Video Name",


  "service.cost.amount": "Consumption amount",
  "service.cost.type": "Type of consumption",
  "service.cost.pieces": "Number of consumed pieces",
  "service.cost.time": "Time consumption",
  "service.cost.videoGenerate": "Video generation",
  "service.cost.DH": "Digital Human production",
  "service.cost.DV": "Digital Vocal production",
  "service.cost.searchTips": "Please select the source of consumption",
  "service.cost.video": "Video",
  "service.cost.vocal": "Voice",


  "service.order.selRechargeSource": "Please select the recharge source",
  "service.order.selRechargeType": "Please select the recharge type",
  "service.order.selPaymentState": "Please select payment status",
  "service.order.inputID": "Please enter the order number",
  "service.order.recharge.wechat": "WeChat",
  "service.order.recharge.Alipay": "Alipay",
  "service.order.recharge.CloudQuickPass": "Cloud QuickPass",
  "service.order.payment.success": "Paid",
  "service.order.payment.failed": "Not Paid",
  "service.order.invoice": "Invoice",
  "service.order.id": "Order ID",
  "service.order.rechargeSource": "Recharge Source",
  "service.order.rechargeType": "Recharge Type",
  "service.order.paymentState": "Payment Status",
  "service.order.num": "Number of orders",
  "service.order.orderTime": "Order Time ",
  "service.order.payTime": "Payment Time",
  "service.order.video": "Video",
  "service.order.digitalVoice": "Digital Vocal",

  "service.translation.selectLan": "Please select language",
  "service.translation.selectVoice": "Please select Voice",
  "service.translation.video": "Video Translation",
  "service.translation.originalLan": "Original Language",
  "service.translation.selOriginalLan": "Please select original language",
  "service.translation.translLan": "Translation Language",
  "service.translation.selTranslLan": "Please select translation language",
  "service.translation.uploadVideo": "Upload Video",
  "service.translation.uploadVideoVail": "Please upload video",
  "service.translation.uploadSuccess": "Uploaded Successfully",
  "service.translation.generating": "Translation Generating",
  "service.translation.wait": "Please Wait...",


  "service.login.login": "Login",
  "service.login.logout": "Logout",
  "service.login.register": "Register",
  "service.login.registerTips": "Register an account",
  "service.login.seeYouAgain": "We are very glad to see you again！",
  "service.login.welcome": "Welcome back!",
  "service.login.codeError": "The verification code is incorrect or has expired",
  "service.login.phoneOrEmail": "Mobile / Email",
  "service.login.code": "Verification code",
  "service.login.send": "Send",
  "service.login.accountPassword": "Login using the account and password",
  "service.login.privacy": "Agree to the user privacy agreement",

  "service.component.digitalVoice": "Digital Vocal",
  "service.component.digitalFigures": "Digital Human",
  "service.component.ppt": "Generate PPT",
  "service.component.onGenerate": "Generating……",
  "service.component.timeEstimate": "Time Estimate",
  "service.component.timeEstimateDetail": "It is expected to take xxx minutes and consume xxx Yuan.",
  "service.component.paySuccess": "Payment successful",


  "service.common.publishSuccess": "Publish successfully",
  "service.common.deleteSuccess": "Delete successfully",
  "service.common.deleteFail": "Delete Failed",
  "service.common.saveSuccess": "Saved successfully",
  "service.common.saveFail": "Save failed",
  "service.common.success": "success",
  "service.common.Edit": "Edit",
  "service.common.confirm": "confirm",
  "service.common.cancel": "cancel",
  "service.common.setting": "Setting",
  "service.common.None": "None",
  "service.common.tip": "Tips",
  "service.common.title": "Title",
  "service.common.enterTitle": "Please enter title",
  "service.common.theBuildSucceeded": "The build succeeded",
  "service.common.downloadFailed": "Download failed",
  "service.common.logoutSuccess": "You have successfully logged out",
  "service.common.logoutFail": "Account cancellation failed",
  "service.common.confirmLogout": "Confirm cancellation",
  "service.common.confirmLogoutTips": "Are you sure you want to cancel？",
  "service.common.uploadFailed": "Upload failed",
  "service.common.uploadFileTooLarge": "The file size cannot exceed 5M",
  "service.common.uploadFileFormatError": "You can only upload files in JPG/PNG format",
  "service.common.emailExist": "The email account already exists",


  "service.notice.errorRequest": "Request Error: ",
  "service.notice.timeout": "Request timed out, please check your network or try again later",
  "service.notice.request202": "The request has been accepted and queued in the background (asynchronous task), but it has not been processed yet",
  "service.notice.request203": "Request successful, unauthorized information, returned meta information is not on the original server, but a copy",
  "service.notice.request204": "The server processed successfully, but did not return any content",
  "service.notice.request205": "Server successfully processed, client (browser) should reset page view",
  "service.notice.request206": "The server successfully processed some GET requests",
  "service.notice.request400": "The request sent is incorrect (client request syntax error), and the server has not performed any new or modified data operations",
  "service.notice.request401": "The user does not have permission (incorrect token, username, password) or the login has expired",
  "service.notice.request403": "The user is authorized, but access has been prohibited",
  "service.notice.request404": "The requested resource cannot be found (the resource does not exist or the URL is incorrect), and the server has not taken any action",
  "service.notice.request405": "The method in the client request is prohibited by the server or the request method is incorrect",
  "service.notice.request406": "The client request format is incorrect or the server returns content that the client cannot parse",
  "service.notice.request410": "The requested resource has been permanently deleted and will no longer be available",
  "service.notice.request412": "The prerequisite for the client to request information is incorrect",
  "service.notice.request413": "Due to the requested entity being too large for the server to process, the request is rejected",
  "service.notice.request414": "The requested URL is too long (usually a web address), and the server cannot process it",
  "service.notice.request415": "The server is unable to process the media format attached to the request",
  "service.notice.request416": "The scope requested by the client is invalid",
  "service.notice.request422": "When creating an object, a validation error occurs",
  "service.notice.request500": "Server error, please check the server",
  "service.notice.request501": "The server does not support the requested function and cannot complete the request",
  "service.notice.request502": "Gateway error, received an invalid response from the remote server",
  "service.notice.request503": "Service unavailable, server temporarily overloaded or under maintenance",
  "service.notice.request504": "Gateway timeout, the server acting as a gateway or proxy did not retrieve requests from the remote server in a timely manner",
  "service.notice.request505": "The server does not support the requested version of the HTTP protocol and cannot complete the processing",
  "service.notice.request604": "Login timeout, please log in again",
  "service.notice.request603": "Login timeout, please log in again",


  "service.username": "userName",
  "service.email": "Email",
  "service.phone": "Phone",
  "service.organization": "Company",
  "service.OK": "Ensure",


  "tips.login.notSupport": "Not Supported",
  "tips.login.success": "Login succeeded",
  "tips.login.fail": "Login failed",
  "tips.login.acc": "Account",
  "tips.login.pass": "Password",
  "tips.login.agreeTips": "Agree to user privacy agreement",
  "tips.login.account": "Please input your account!",
  "tips.login.password": "login with verification code",
  "tips.login.loginWithVerify": "Login and register using the verification code",
  "tips.login.check": "view",
  "tips.privacy.title": "User Privacy Agreement",
  "tips.login.theAccountNumberOrPasswordIsIncorrect": "the account with this keycode was not found",
  "tips.login.getAVerificationCode": "Obtain a verification code",
  "tips.login.RetryinSeconds": "Retry in seconds",

  "btn.text.release": "release",
  "btn.text.modify": "modify",

  "service.language.cn": "Chinese(default)",
  "service.language.en": "English",
  "service.language.ja": "Japanese",
  "service.language.ru": "Russian",
  "service.language.ko": "Korean",
  "service.language.de": "German",
  "service.language.fr": "French",
  "service.language.pt": "Portuguese",
  "service.language.es": "Spanish",
  "service.language.it": "Italian",
  "service.language.th": "Thai",

  "service.user.center": "User Center",
  "service.user.updateAvatar": "Modify picture",
  "service.user.avatarFormat": "Pictures should be larger than 500*500 pixels, support JPG, JPEG, PNG and SVG formats, and the size should not exceed 5M",
  "service.user.nickname": "Nickname",
  "service.user.cancelAccount": "Cancel Account",
  "service.user.cancelAccountWarning": "It cannot be recovered after logging out, please be careful",
  "service.user.logOff": "Logout",
  "service.user.verification": "Identity verification",
  "service.user.verifyIdentity": "For the security of your account, please verify your identity first before proceeding with the next step.",
  "service.user.useMobile": "Use",
  "service.user.completeVerification": "Complete the verification",
  "service.user.send": "Send",
  "service.user.enterCode": "Please enter the verification code",
  "service.user.resend": "Resend",
  "service.user.changePassword": "Change the password",
  "service.user.enterNewPassword": "Please enter the new password",
  "service.user.LimitPassword": "Please enter a new password. The password length should be 6 - 20 characters and must contain two of the following: uppercase letters, lowercase letters, numbers, and special characters",
  "service.user.LimitLengthPassword": "The password length must be between 6 and 20 characters",
  "service.user.LimitFormatPassword": "The password must contain two of the following: uppercase letters, lowercase letters, numbers, and special characters",
}

export default engLanguage;
