import "./index.scss";

/**
 * 公共图标组件，通过这个组件直接将svg文件包裹为React组件，不用再单独给每个svg图标写js文件
 * @param name 图标名
 * @param size 大小
 * @param fill 颜色
 * @returns {JSX.Element}
 * @constructor
 */
const IconComp = ({name, width, height, fill}) => {
    if (fill) {
        return (
            <svg className="icon-style" aria-hidden="true" width={width} height={height}>
                <use xlinkHref={'#icon-' + name} style={{color: fill}}></use>
            </svg>
        )
    } else {
        return (
            <svg className="icon-style" aria-hidden="true" width={width} height={height}>
                <use xlinkHref={'#icon-' + name}></use>
            </svg>
        )
    }
}

export default IconComp;