/**
 * 获取浏览器的语言
 * @type {string}
 */

export const navigateLanguage = navigator.language || navigator.userLanguage;

/**
 * key value对应
 * @type {Map<string, string>}
 */
export const languageAreaMap = new Map([
    [
        "zh-CN", "zh"
    ], 
    [
        "zh", "zh"
    ], [
        "en-US","en",
    ],[
        "en","en"
    ],[
        "ja","ja",
    ],[
        "ko","ko"
    ],[
        "ko-KR","ko"
    ],[
        "fr","fr"
    ]
]);