import enUS from "@/locales/en_US";
import zhCN from "@/locales/zh_CN";
import frFR from "@/locales/fr_FR";
import koKR from "@/locales/ko_KR";
import jaJP from "@/locales/ja_JP";

export const locales = {
    'en': enUS,
    'zh': zhCN,
    // 'fr': frFR,
    'ko': koKR,
    'ja': jaJP,
}