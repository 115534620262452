import {useRoutes} from "react-router-dom";
import route from "@/router";

import GeekLayout from "@/layouts";
import AuthToken from "@/components/AuthToken";


/**
 * app
 * @returns {JSX.Element}
 * @constructor
 */
function App() {

    const routeElement = useRoutes(route);

    /**
     * 是否在菜单中展示
     */
    const isShowInMenu = routeElement.props.match.route.meta.showInMenu;


    if (!isShowInMenu) {
        return (
            <>{routeElement}</>
        )
    } else {
        return (
            <GeekLayout>
                <AuthToken>
                    {routeElement}
                </AuthToken>
            </GeekLayout>
        )
    }
}

export default App;