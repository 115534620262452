import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {getUserInfo} from "@/utils/token";
import publishNotice from "@/components/Notice";


/**
 * 认证组件，监听本地localStorage，如果有登录信息渲染组件，否则跳转到登录界面
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AuthToken({children}) {

    const navigate = useNavigate();


    const userInfo = getUserInfo();

    // 监听本地值，一旦被删除，跳转到登录页
    window.addEventListener('storage', (event) => {
        if (event.storageArea === localStorage && !getUserInfo()) {
            navigate("/login");
            publishNotice('warning', '监测到用户信息已变更或删除，请重新登录');
        }
    })

    if (userInfo && userInfo.token) {
        // 如果token存在，则渲染传入的组件
        return <>{children}</>
    } else {
        // 否则重定向到登录组件
        return <Navigate to="/login" replace></Navigate>
    }
}

export default AuthToken;